import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { parse, format } from 'date-fns';

const GameBox = ({ game, isLongshot = false }) => {
  const formatScore = (score) => {
    return Number(score).toFixed(3);
  };

  const formatDateTime = (dateTimeStr) => {
    try {
      const date = parse(dateTimeStr, 'MM/dd/yyyy hh:mm a', new Date());
      return format(date, 'MM/dd/yyyy hh:mm a');
    } catch (error) {
      console.error("Error parsing date:", dateTimeStr, error);
      return dateTimeStr;
    }
  };

  const homeScore = formatScore(game.h_score);
  const awayScore = formatScore(game.a_score);
  const homeWinning = parseFloat(homeScore) > parseFloat(awayScore);
  const awayWinning = parseFloat(awayScore) > parseFloat(homeScore);

  const winProbability = parseFloat(game.win_prob);
  const loseProbability = (100 - winProbability).toFixed(3);

  const homeImpliedOdds = game.implied_odds;
  const awayImpliedOdds = game.implied_odds * -1;

  const spread = (parseFloat(homeScore) - parseFloat(awayScore)).toFixed(3);
  const totalScore = (parseFloat(homeScore) + parseFloat(awayScore)).toFixed(3);

  return (
    <Link
      to={`/game/${encodeURIComponent(game.home_team)}${encodeURIComponent(game.time)}`}
      className="block text-inherit hover:shadow-md transition-shadow w-full"
    >
      <div className="flex flex-col p-4 border border-gray-300 rounded-lg bg-white w-full">
        <div className="pb-2">{game.sport}</div>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex flex-col items-center sm:items-start w-full sm:w-1/2 mb-2 sm:mb-0">
            {game.h_logo !== 'NO LOGO' && (
              <img src={game.h_logo} alt={`${game.home_team} logo`} className="w-12 h-12 object-contain mb-1 sm:mb-0 sm:mr-2" />
            )}
            <div className="text-center sm:text-left">
              <span className="font-bold">{game.home_team}</span>
              <span className={`block ${homeWinning ? 'text-green-500' : 'text-red-500'}`}>{homeScore}</span>
              <span className="block text-gray-500 text-sm">
                {homeWinning ? `(${winProbability}%)` : `(${loseProbability}%)`}
              </span>
              {isLongshot && (
                <>
                  <span className="block text-purple-500 text-sm">
                    Implied Odds: {homeImpliedOdds > 0 ? '+' : ''}{homeImpliedOdds}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center sm:items-start w-full sm:w-1/2">
            {game.a_logo !== 'NO LOGO' && (
              <img src={game.a_logo} alt={`${game.away_team} logo`} className="w-12 h-12 object-contain mb-1 sm:mb-0 sm:mr-2" />
            )}
            <div className="text-center sm:text-left">
              <span className="font-bold">{game.away_team}</span>
              <span className={`block ${awayWinning ? 'text-green-500' : 'text-red-500'}`}>{awayScore}</span>
              <span className="block text-gray-500 text-sm">
                {awayWinning ? `(${winProbability}%)` : `(${loseProbability}%)`}
              </span>
              {isLongshot && (
                <>
                  <span className="block text-purple-500 text-sm">
                    Implied Odds: {awayImpliedOdds > 0 ? '+' : ''}{awayImpliedOdds}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center sm:items-end justify-center min-w-[100px] mt-2 sm:mt-0">
          <div className="text-sm text-gray-500">{formatDateTime(game.time)}</div>
          {isLongshot && (
            <div className="mt-2 text-center w-full">
              <div className="text-sm font-medium text-indigo-600">
                Spread: {spread > 0 ? '+' : ''}{spread}
              </div>
              <div className="text-sm font-medium text-indigo-600">
                Total: {totalScore}
              </div>
            </div>
          )}
          <div className="text-blue-500 text-xs mt-1">Click for more details</div>
        </div>
      </div>
    </Link>
  );
};

const PicksPage = ({ picks, cur_date, user }) => {
  const [selectedSport, setSelectedSport] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [availableSports, setAvailableSports] = useState([]);
  const [dailyLockz, setDailyLockz] = useState([]);
  const [activeTab, setActiveTab] = useState('picks');
  const navigate = useNavigate();

  useEffect(() => {
    const sports = picks.reduce((acc, curr) => {
      if (!acc.includes(curr.sport)) {
        acc.push(curr.sport);
      }
      return acc;
    }, []);
    setAvailableSports(sports);
  }, [picks]);

  useEffect(() => {
    const dailyLockzPicks = picks.filter(pick => pick.is_dl === 'True');
    setDailyLockz(dailyLockzPicks);
  }, [picks]);

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPicks = picks.filter((pick) => {
    if (selectedSport !== 'All' && pick.sport !== selectedSport) {
      return false;
    }

    const normalizedQuery = searchQuery.toLowerCase().trim();
    return (
      pick.home_team.toLowerCase().includes(normalizedQuery) ||
      pick.away_team.toLowerCase().includes(normalizedQuery)
    );
  });

  const handleLoginClick = () => {
    navigate('/account');
  };

  const renderGames = (games, isLongshot = false) => {
    const shouldBlur = !user && !isLongshot && games.length > 1;
    
    return (
      <div className={`space-y-4 relative ${isLongshot ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4' : ''}`}>
        {games.map((game, index) => (
          <GameBox key={index} game={game} isLongshot={isLongshot} />
        ))}
        {shouldBlur && (
          <div className="absolute inset-0 bg-gray-200 bg-opacity-50 backdrop-blur-sm flex flex-col items-center justify-start pt-8">
            <p className="text-lg font-semibold mb-4">Login to view all games</p>
            <button
              onClick={handleLoginClick}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Login
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-3xl font-bold mb-4">Upcoming Predicted Scores</h2>
      <p className="text-sm text-gray-500 mb-4">Last updated: {cur_date}</p>

      <div className="flex mb-4 border-b">
        <button
          className={`py-2 px-4 font-semibold ${activeTab === 'picks' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
          onClick={() => setActiveTab('picks')}
        >
          All Picks
        </button>
        <button
          className={`py-2 px-4 font-semibold ${activeTab === 'longshot' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
          onClick={() => setActiveTab('longshot')}
        >
          Daily Lockz Longshot
        </button>
      </div>

      {activeTab === 'picks' && (
        <>
          <div className="flex flex-col sm:flex-row items-center mb-4">
            <label htmlFor="sport-filter" className="mr-2 font-bold">Filter by Sport:</label>
            <select
              id="sport-filter"
              value={selectedSport}
              onChange={handleSportChange}
              className="p-2 border border-gray-300 rounded mb-2 sm:mb-0"
            >
              <option value="All">All</option>
              {availableSports.map((sport, index) => (
                <option key={index} value={sport}>{sport}</option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Search team name..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="ml-0 sm:ml-2 mb-2 sm:mb-0 p-2 border border-gray-300 rounded"
            />
          </div>
          {filteredPicks.length === 0 ? (
            <p className="text-gray-500">No Games Available</p>
          ) : (
            <div>
              {filteredPicks.length > 1 && !user && (
                <div className="mb-4">
                  <GameBox game={filteredPicks[0]} />
                </div>
              )}
              {renderGames(filteredPicks.slice(filteredPicks.length > 1 && !user ? 1 : 0))}
            </div>
          )}
        </>
      )}

      {activeTab === 'longshot' && (
        <div className="space-y-6">
          <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white p-6 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold mb-2 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd" />
              </svg>
              Daily Lockz Longshot of the Day
            </h3>
            <p className="text-lg mb-4">A *FREE* Daily 10-leg Moneyline Parlay to make $$ Quick</p>
            <span className="inline-block bg-white text-purple-500 text-sm px-2 py-1 rounded">Check back daily for a new parlay!</span>
          </div>
          
          {dailyLockz.length === 0 ? (
            <div className="bg-white shadow rounded-lg p-6 flex items-center justify-center h-40">
              <p className="text-gray-500 text-lg">No Daily Lockz available</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {dailyLockz.map((game, index) => (
                <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
                  <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white p-4">
                    <h4 className="text-lg font-semibold">{game.sport}</h4>
                  </div>
                  <div className="p-4">
                    <GameBox game={game} isLongshot={true} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PicksPage;