import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fm from 'front-matter';
import Papa from 'papaparse';
import seedrandom from 'seedrandom';
import { Trophy, TrendingUp, Target } from 'lucide-react';

const HomePage = () => {
  const [games, setGames] = useState([]);
  const [posts, setPosts] = useState([]);

  const handleViewAllPicks = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch('/all_sims.csv');
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value);
        const { data } = Papa.parse(csv, { header: true });
        
        const gameList = data
          .filter(game => 
            game.home_team && 
            game.away_team && 
            game.h_score && 
            game.a_score && 
            game.h_logo &&
            game.a_logo &&
            game.time &&
            game.home_team !== game.away_team
          )
          .map(game => ({
            home_team: game.home_team,
            away_team: game.away_team,
            h_score: game.h_score,
            a_score: game.a_score,
            h_logo: game.h_logo,
            a_logo: game.a_logo,
            time: game.time,
          }));

        const seed = 'dailylockz';
        const rng = seedrandom(seed);

        const shuffledGames = gameList
          .map(value => ({ value, sort: rng() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);

        setGames(shuffledGames.slice(0, 3));
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const importAll = (r) => r.keys().map(r);
        const markdownFiles = importAll(require.context('../blog-posts', false, /\.md$/));

        const fileContents = await Promise.all(markdownFiles.map(file => fetch(file).then(res => res.text())));
        
        const parsedPosts = fileContents.map(file => {
          const { attributes, body } = fm(file);
          return { ...attributes, content: body };
        });

        const sortedPosts = parsedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

        setPosts(sortedPosts.slice(0, 2));
      } catch (error) {
        console.error('Error fetching or parsing markdown files:', error);
      }
    };

    fetchGames();
    fetchPosts();
  }, []);

  return (
    <div className="bg-white text-gray-900 min-h-screen font-sans">
      {/* Header Section */}
      <header className="relative bg-gradient-to-br from-blue-600 via-indigo-700 to-purple-800 text-white overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-48 h-48 bg-white rounded-full mix-blend-overlay blur-3xl animate-blob"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-white rounded-full mix-blend-overlay blur-3xl animate-blob animation-delay-4000"></div>
      </div>

      {/* Content Container */}
      <div className="relative max-w-6xl mx-auto px-6 py-20 text-center">
        {/* Animated Title */}
        <h1 className="text-6xl font-black mb-6 tracking-tight 
          bg-clip-text text-transparent 
          bg-gradient-to-r from-white via-white to-blue-200
          animate-gradient-x">
          Daily Lockz
        </h1>

        {/* Subheader with Dynamic Highlights */}
        <p className="text-2xl max-w-3xl mx-auto mb-10 font-medium text-gray-100 leading-relaxed">
          Unlock <span className="text-yellow-300 font-bold">Predictive Power</span> with AI-Driven Sports Analytics
        </p>

        {/* Key Value Propositions */}
        <div className="flex justify-center space-x-6 mb-12">
          {[
            { 
              icon: <TrendingUp strokeWidth={2} className="w-8 h-8 mb-2 mx-auto text-blue-300"/>, 
              text: "99M+ Simulations" 
            },
            { 
              icon: <Target strokeWidth={2} className="w-8 h-8 mb-2 mx-auto text-green-300"/>, 
              text: "Precision Predictions" 
            },
            { 
              icon: <Trophy strokeWidth={2} className="w-8 h-8 mb-2 mx-auto text-yellow-300"/>, 
              text: "Proven Accuracy" 
            }
          ].map((item, index) => (
            <div 
              key={index} 
              className="bg-white/10 backdrop-blur-sm rounded-xl p-4 text-center transition-all hover:bg-white/20 hover:scale-105"
            >
              {item.icon}
              <div className="text-sm font-medium">{item.text}</div>
            </div>
          ))}
        </div>

        {/* Call to Action Buttons */}
        <div className="flex justify-center space-x-4">
          <Link 
            to="/games" 
            className="px-8 py-4 bg-white text-indigo-800 rounded-full 
            font-bold hover:bg-blue-100 transition-all 
            shadow-xl hover:shadow-2xl transform hover:-translate-y-1
            flex items-center space-x-2"
          >
            <span>View Today's Picks</span>
          </Link>
          <Link 
            to="/about" 
            className="px-8 py-4 border-2 border-white/30 text-white 
            rounded-full font-bold hover:bg-white/10 
            transition-all shadow-lg hover:shadow-xl"
          >
            Our Methodology
          </Link>
        </div>
      </div>

      {/* Optional: Subtle Animated Background */}
      <style jsx>{`
        @keyframes blob {
          0% { transform: translate(0, 0) scale(1); }
          33% { transform: translate(30px, -50px) scale(1.1); }
          66% { transform: translate(-20px, 20px) scale(0.9); }
          100% { transform: translate(0, 0) scale(1); }
        }
        .animate-blob {
          animation: blob 15s infinite;
        }
        .animation-delay-4000 {
          animation-delay: 4s;
        }
        @keyframes gradient-x {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient-x {
          background-size: 200% 200%;
          animation: gradient-x 5s ease infinite;
        }
      `}</style>
    </header>

      {/* Features Grid */}
      <section className="max-w-6xl mx-auto px-6 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              title: "Precision Predictions",
              description: "Leveraging advanced machine learning to deliver highly accurate sports forecasts.",
              icon: "📊"
            },
            {
              title: "Comprehensive Coverage",
              description: "Tracking major US leagues and international soccer with real-time updates.",
              icon: "🌐"
            },
            {
              title: "Advanced Modeling",
              description: "Simulating scenarios 99,000,000 times to refine our predictive accuracy.",
              icon: "🤖"
            }
          ].map((feature, index) => (
            <div 
              key={index} 
              className="bg-white border border-gray-200 rounded-2xl p-6 text-center transform transition-all hover:scale-105 hover:shadow-2xl"
            >
              <div className="text-5xl mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Featured Games */}
      <section className="max-w-6xl mx-auto px-6 py-16 bg-gray-50">
        <h2 className="text-3xl font-semibold text-center mb-10">Featured Games</h2>
        {games.length > 0 ? (
          <div className="grid md:grid-cols-3 gap-6">
            {games.map((game, index) => (
              <div 
                key={index} 
                className="bg-white border border-gray-200 rounded-2xl p-6 hover:shadow-lg transition-shadow"
              >
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">{game.time}</span>
                  <Link 
                    to={`/game/${encodeURIComponent(game.home_team)}${encodeURIComponent(game.time)}`} 
                    className="text-blue-600 hover:text-blue-800 transition-colors"
                    onClick={handleViewAllPicks}
                  >
                    View Details
                  </Link>
                </div>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex flex-col items-center">
                    <img 
                      src={game.away_team_logo || game.a_logo} 
                      alt={`${game.away_team} logo`} 
                      className="w-16 h-16 object-contain mb-2"
                    />
                    <div className="text-lg font-medium text-center">{game.away_team}</div>
                  </div>
                  <div className="text-xl font-bold mx-4">VS</div>
                  <div className="flex flex-col items-center">
                    <img 
                      src={game.home_team_logo || game.h_logo} 
                      alt={`${game.home_team} logo`} 
                      className="w-16 h-16 object-contain mb-2"
                    />
                    <div className="text-lg font-medium text-center">{game.home_team}</div>
                  </div>
                </div>
                <div className="text-center text-sm text-gray-600">
                  Score Prediction: {game.a_score} - {game.h_score}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No games available at the moment.</p>
        )}
      </section>

      {/* Blog Posts */}
      <section className="max-w-6xl mx-auto px-6 py-16">
        <h2 className="text-3xl font-semibold text-center mb-10">Recent Insights</h2>
        {posts.length > 0 ? (
          <div className="grid md:grid-cols-2 gap-6">
            {posts.map((post, index) => (
              <Link 
                key={index} 
                to={`/blog/${post.id}`} 
                className="bg-white border border-gray-200 rounded-2xl p-6 hover:shadow-lg transition-shadow block"
              >
                <h3 className="text-xl font-semibold mb-3 text-gray-900">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="text-sm text-gray-500">
                  {new Date(post.date).toLocaleDateString()}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No posts available at the moment.</p>
        )}
      </section>

      {/* Newsletter */}
      <section className="max-w-4xl mx-auto px-6 py-16 text-center">
        <h2 className="text-3xl font-semibold mb-4">Stay Ahead of the Game</h2>
        <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
          Receive personalized sports predictions, insights, and exclusive content directly in your inbox.
        </p>
        <form className="max-w-lg mx-auto flex flex-col sm:flex-row gap-4">
          <input 
            type="email" 
            placeholder="Enter your email" 
            className="flex-1 px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button 
            type="submit" 
            className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors font-medium shadow-lg"
          >
            Subscribe
          </button>
        </form>
        <p className="text-xs text-gray-500 mt-4">
          We respect your privacy. Unsubscribe at any time.
        </p>
      </section>
    </div>
  );
};

export default HomePage;